import '@babel/polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import VueClipboard from 'vue-clipboard2';
import VTooltip from 'v-tooltip';
import vClickOutside from 'v-click-outside';
import VModal from 'vue-js-modal';
import VueCookie from 'vue-cookie';
import autosize from 'autosize';
import lodash from 'lodash';
import { MdField, MdButton } from 'vue-material/dist/components';
import KProgress from 'k-progress';
import sanitizeHtml from 'sanitize-html';
import jsesc from 'jsesc';
import App from './App.vue';
import router from './router';
import store from './store';
import './common/errors';
import './assets/js/validation';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import './assets/style/components/vue-material-custom.scss';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.directive('autosize', {
  bind(el) {
    Vue.nextTick(() => {
      const tagName = el.tagName;
      if (tagName === 'TEXTAREA') {
        autosize(el);
      }
    });
  },
  componentUpdated(el) {
    Vue.nextTick(() => {
      const tagName = el.tagName;
      if (tagName === 'TEXTAREA') {
        autosize.update(el);
      }
    });
  },
  unbind(el) {
    autosize.destroy(el);
  },
});

Vue.use(Toasted);
Vue.use(VueClipboard);
Vue.use(VTooltip);
Vue.use(vClickOutside);
Vue.use(MdField);
Vue.use(MdButton);
Vue.use(VueCookie);
Vue.use(VModal);
Vue.use(KProgress);
Vue.use(jsesc);
Vue.use(sanitizeHtml);
Vue.prototype._ = lodash;
Vue.prototype.$sanitize = sanitizeHtml;
Vue.prototype.$formatValue = (value, append) => {
  if (value === -1) return '-';
  if (append) return `${value.toFixed(2)} ${append}`;
  return value.toFixed(2);
};
smoothscroll.polyfill();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
