import Vue from 'vue';
import Vuex from 'vuex';
import trial from '@/store/trial.module';
import trialFeedback from '@/store/trial-feedback.module';
import privacyPolicy from '@/store/privacy-policy.module';
import admin from '@/store/admin.mudule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    trial,
    trialFeedback,
    privacyPolicy,
  },
});
