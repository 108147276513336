export const getDisplayName = () => localStorage.getItem('DISPLAY_NAME');
export const getUsername = () => localStorage.getItem('USERNAME');
export const getRole = () => localStorage.getItem('ROLE');
export const getAuthorities = () => localStorage.getItem('AUTHORITIES');
export const getSurveyParams = () => JSON.parse(localStorage.getItem('SURVEY_PARAMS'));
export const getUserParams = () => JSON.parse(localStorage.getItem('USER_PARAMS'));

export const setDisplayName = (username) => { localStorage.setItem('DISPLAY_NAME', username); };
export const setUsername = (username) => { localStorage.setItem('USERNAME', username); };
export const setRole = (role) => { localStorage.setItem('ROLE', role); };
export const setAuthorities = (authorities) => { localStorage.setItem('AUTHORITIES', authorities); };
export const setSurveyParams = (survey) => { localStorage.setItem('SURVEY_PARAMS', JSON.stringify({ survey })); };
export const setUserParams = (user) => { localStorage.setItem('USER_PARAMS', JSON.stringify(user)); };
export const clearUser = () => {
  localStorage.removeItem('USERNAME');
  localStorage.removeItem('DISPLAY_NAME');
  localStorage.removeItem('ROLE');
};
