import Vue from 'vue';
import axios from 'axios';
import { API_URL } from './config';
import router from '../router';

export const ApiService = {
  downloadFile(resource) {
    const api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
      responseType: 'blob',
    });
    api.interceptors.request.use((config) => {
      config.headers = {
        'X-AUTH-TOKEN': Vue.cookie.get('TOKEN'),
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      };
      return config;
    }, error => error);
    return api.get(`${resource}`);
  },
  instance() {
    const instance = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });
    instance.interceptors.request.use((config) => {
      config.headers = {
        'X-AUTH-TOKEN': Vue.cookie.get('TOKEN'),
        'Content-Type': 'application/json',
      };
      return config;
    }, error => error);
    instance.interceptors.response.use(response => response, (error) => {
      if (error.response.status === 400) {
        router.push({ name: 'bad-request', params: error.response.data }, () => {});
        return Promise.resolve(error.response);
      }
      if (error.response.status === 401) {
        router.push('/login', () => {});
        return Promise.reject(error);
      }
      if (error.response.status === 403) {
        router.push({ name: 'forbidden', params: error.response.data }, () => {});
        return Promise.resolve(error.response);
      }
      if (error.response.status === 404) {
        router.push({ name: 'not-found', params: error.response.data }, () => {});
        return Promise.resolve(error.response);
      }
      if (error.response.status >= 500) {
        router.push({ name: 'error', params: error.response.data }, () => {});
        return Promise.resolve(error);
      }
      return Promise.resolve(error.response);
    });
    return instance;
  },
  get(resource, slug = '') {
    return this.instance().get(`${resource}/${slug}`);
  },
  getWithQueryParams(resource, params) {
    return this.instance().get(`${resource}`, params);
  },
  post(resource, params, headers) {
    return this.instance().post(`${resource}`, params, headers);
  },
  put(resource, params) {
    return this.instance().put(`${resource}`, params);
  },
  delete(resource, params) {
    return this.instance().delete(`${resource}`, params);
  },
  queryImage(resource, params) {
    const api = axios.create({
      baseURL: 'http://localhost:5000',
      withCredentials: true,
      responseType: 'blob',
    });
    api.interceptors.request.use((config) => {
      config.headers = {
        'X-AUTH-TOKEN': Vue.cookie.get('TOKEN'),
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      };
      return config;
    }, error => error);
    return api.post(`${resource}`, params);
  },
};

export default ApiService;
