<template>
  <div id="app">
    <loading v-if="loading"/>
    <router-view />
  </div>
</template>
<script>
import Loading from '@/components/Common/Loading.vue';
import bus from '@/common/bus.util';

export default {
  name: 'App',
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    startSpinner() {
      this.loading = true;
    },
    endSpinner() {
      this.loading = false;
    },
  },
  created() {
    bus.$on('start:spinner', this.startSpinner);
    bus.$on('end:spinner', this.endSpinner);
  },
  beforeDestroy() {
    bus.$off('start:spinner');
    bus.$off('end:spinner');
  },
};
</script>
<style lang="scss">
@import '~@/assets/style/common.scss';

html, body {
  margin: 0;
  height: 100%;
}
input {
  &::-ms-clear {
    display: none;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $nero_grey;
  font-size: $default_font_size;
  display: flex;
  justify-content: center;
  height: 100%;
  background: $white;
  overflow: hidden;
  line-height: normal;
}
</style>
