import Vue from 'vue';
import { ApiService } from '@/common/api.service';
import { clearUser } from '@/common/local-storage';

const state = {};
const getters = {};
const mutations = {
  logout() {
    Vue.cookie.delete('TOKEN');
    clearUser();
  },
};
const actions = {
  async checkUsername(context, username) {
    const { data } = await ApiService.get(`/user/${username}/check`);
    return data;
  },
  async addUser(context, user) {
    const { data } = await ApiService.post('/user', user);
    return data;
  },
  async getUsers() {
    const { data } = await ApiService.get('/user');
    return data;
  },
  async deleteUser(context, username) {
    const { data } = await ApiService.delete(`/user/${username}`);
    return data;
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
