import Vue from 'vue';
import VueRouter from 'vue-router';
import { isInternetExplore, isMobile } from '@/common/browser.util';
import { errorType } from '@/assets/js/code';

Vue.use(VueRouter);

const routes = [
  {
    name: 'main',
    path: '/',
    component: () => import('../views/Main.vue'),
    children: [
      {
        name: 'editor',
        path: 'editor',
        component: () => import('../views/Editor.vue'),
      },
      {
        name: 'feedback',
        path: 'feedback',
        component: () => import('../views/Feedback.vue'),
      },
    ],
  },
  {
    name: 'trial',
    path: '/survey/:id/trial',
    component: () => import('../views/Survey.vue'),
    beforeEnter(to, from, next) {
      if (from.name === 'result') {
        next(`/survey/${from.params.id}`);
      } else if (from.name !== 'intro' || from.path !== `/survey/${from.params.id}`) {
        next(`/survey/${to.params.id}`);
      } else {
        next();
      }
    },
  },
  {
    name: 'fp-trial',
    path: '/fp/:id/trial',
    component: () => import('../views/Survey.vue'),
    beforeEnter(to, from, next) {
      if (from.name !== 'fp-intro') {
        next(`/fp/${to.params.id}`);
      } else {
        next();
      }
    },
  },
  {
    name: 'admin',
    path: '/admin',
    // eslint-disable-next-line import/extensions
    component: () => import('../views/Admin'),
    children: [
      {
        name: 'User',
        path: '/',
        component: () => import('../views/Admin/User.vue'),
      },
    ],
  },
  {
    name: 'intro',
    path: '/survey/:id',
    component: () => import('../views/SurveyIntro.vue'),
  },
  {
    name: 'fp-intro', // My Trial 로그인 x, 고객 정보 전송 x  => free pass
    path: '/fp/:id',
    component: () => import('../views/FreePassIntro.vue'),
  },
  {
    name: 'result',
    path: '/result/:id',
    component: () => import('../views/Result.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/Login.vue'),
  },
  {
    name: 'bad-request',
    path: '/bad-request',
    component: () => import('../views/Error/BadRequest.vue'),
    props: true,
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () => import('../views/Error/Forbidden.vue'),
    props: true,
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: () => import('../views/Error/NotFound.vue'),
    props: true,
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('../views/Error/InternalServerError.vue'),
    props: true,
  },
  {
    name: 'privacy-and-policy',
    path: '/privacyandpolicy',
    component: () => import('../views/PrivacyAndPolicy.vue'),
  },
  {
    name: 'terms-and-use',
    path: '/termsanduse',
    component: () => import('../views/TermsAndUse.vue'),
  },
  {
    name: 'stats',
    path: '/stats',
    component: () => import('../views/Stats.vue'),
  },
];


const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if ((isInternetExplore() || isMobile())
    && !['result', 'intro', 'fp-intro', 'trial', 'fp-trial', 'terms-and-use', 'privacy-and-policy'].includes(to.name)
    && to.name !== 'not-found') {
    next({ name: 'not-found', params: { type: errorType.NOT_SUPPORT_IE } });
  } else {
    next();
  }
  // else if (isMobile() && to.name !== 'not-found') {
  //   next({ name: 'not-found', params: { type: errorType.NOT_SUPPORT_MOBILE } });
  // }
});

export default router;
