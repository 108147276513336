import Vue from 'vue';
import flatten from 'lodash/flatten';
import { ApiService } from '@/common/api.service';
import { decodeToken } from '@/common/jwt-token';
import {
  clearUser,
  setAuthorities,
  setDisplayName,
  setRole,
  setUsername,
} from '@/common/local-storage';

const initTrialSurvey = {
  id: '',
  mixedId: '',
  questionnaireId: '',
  tempTitle: '',
  tempDescription: '',
  title: '',
  description: '',
  progress: '',
  createdDate: '',
  updatedDate: '',
  uploadedFileName: '',
};

const state = {
  trialSurvey: initTrialSurvey,
  trialQuestions: [],
  template: null,
  isClicked: false,
  isCompletedSaveData: false,
  isCompletedTimer: false,
  trialAnswers: {},
  submissionId: '',
  participantToken: '',
  mySubmissionResult: {
    trialCategoryDiagnoses: [],
  },
  isChanged: false,
  entryPoint: '',
};

const getters = {
  trialSurvey(_state) {
    return _state.trialSurvey;
  },
  trialQuestions(_state) {
    return _state.trialQuestions;
  },
  isClicked(_state) {
    return _state.isClicked;
  },
  isTrialLoading(_state) {
    return !(_state.isCompletedSaveData && _state.isCompletedTimer) && _state.isClicked;
  },
  getAnswers(_state) {
    return _state.trialAnswers;
  },
  trialSubmissionId(_state) {
    return _state.submissionId;
  },
  mySubmissionResult(_state) {
    return _state.mySubmissionResult;
  },
  isChanged(_state) {
    return _state.isChanged;
  },
  participantToken(_state) {
    return _state.participantToken;
  },
  entryPoint(_state) {
    return _state.entryPoint;
  },
};

const mutations = {
  setTrialSurvey(_state, data) {
    _state.trialSurvey = {
      ...data,
    };
  },
  setTempTitleAndDescription(_state, data) {
    _state.trialSurvey.tempTitle = data.tempTitle;
    _state.trialSurvey.tempDescription = data.tempDescription;
  },
  setTitleAndDescription(_state, data) {
    _state.trialSurvey.title = data.tempTitle;
    _state.trialSurvey.description = data.tempDescription;
  },
  setTrialProgress(_state, data) {
    _state.trialSurvey.progress = data;
  },
  setCreatedDate(_state, data) {
    _state.trialSurvey.createdDate = data;
  },
  setUpdatedDate(_state, data) {
    _state.trialSurvey.updatedDate = data;
  },
  setIsClicked(_state, data) {
    _state.isClicked = data;
  },
  setIsCompletedSaveData(_state, data) {
    _state.isCompletedSaveData = data;
  },
  setIsCompletedTimer(_state, data) {
    _state.isCompletedTimer = data;
  },
  clearTrialSurvey(_state) {
    _state.trialSurvey = initTrialSurvey;
    _state.isClicked = false;
    _state.isCompletedSaveData = false;
    _state.isCompletedTimer = false;
    _state.isChanged = false;
  },
  setTrialSurveyUploadedFileName(_state, data) {
    _state.trialSurvey = {
      ..._state.trialSurvey,
      uploadedFileName: data,
    };
  },
  setTrialQuestions(_state, data) {
    const categories = data.categories;
    _state.trialQuestions = flatten(categories.map(category => category.questions
      .map(question => ({
        ...question,
        categoryId: category.id,
        categoryName: category.name,
      }))))
      .map((question, index) => ({ ...question, index }));
  },
  setAnswers(_state, data) {
    _state.trialAnswers[data.id] = data.value;
  },
  setAnswersClean(_state) {
    _state.trialAnswers = {};
  },
  setSubmissionId(_state, data) {
    _state.submissionId = data;
  },
  setMySubmissionResult(_state, data) {
    _state.mySubmissionResult = data;
  },
  setIsChanged(_state, data) {
    _state.isChanged = data;
  },
  setParticipantToken(_state, data) {
    _state.participantToken = data;
  },
  setEntryPoint(_state, data) {
    _state.entryPoint = data;
  },
};

const actions = {
  async getDefaultTrialSurveyTemplate() {
    const response = await ApiService.downloadFile('/trial/excelTemplate');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '[sally](Trial) Upload Template.xlsx');
    document.body.appendChild(link);
    link.click();
  },
  async downloadStatsExcelFile() {
    const response = await ApiService.downloadFile('/stats/excel');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sally-raw-data.xlsx');
    document.body.appendChild(link);
    link.click();
  },
  async trialLogin(context, params) {
    const { data } = await ApiService.post('/api/login', params);
    if (data.loginSuccess && data.token) {
      Vue.cookie.set('TOKEN', data.token);
      const {
        displayName,
        role,
        sub,
        authorities,
      } = decodeToken(data.token);
      setRole(role);
      setDisplayName(displayName);
      setUsername(sub);
      setAuthorities(authorities);
    }
    return {
      isLoginSuccess: !!data.loginSuccess,
      isApproved: !!data.approved,
      loginFailCount: data.loginFailCount,
    };
  },
  async trialLogout() {
    Vue.cookie.delete('TOKEN');
    clearUser();
  },
  async fetchTrialSurvey({ commit }) {
    const { data } = await ApiService.get('/trial');
    commit('setTrialSurvey', data);
  },
  async fetchTrialSurveyWithMixedId({ commit }, id) {
    const { data } = await ApiService.get(`/api/trial/${id}/mixedId`);
    commit('setTrialSurvey', data);
    commit('setParticipantToken', data.participantToken);
  },
  async saveTrialSurvey({ commit }) {
    const { data } = await ApiService.post('/trial');
    commit('setTrialSurvey', data);
  },
  async modifyTempTitleAndDescription({ commit }, params) {
    await ApiService.put(`/trial/${params.id}/tempTitleAndDescription`, {
      tempTitle: params.tempTitle,
      tempDescription: params.tempDescription,
    });
    commit('setTempTitleAndDescription', params);
  },
  async modifyTempExcelData(context, id) {
    await ApiService.put(`/trial/${id}/tempExcelData`);
  },
  async modifyTrialSurvey(context, params) {
    const { data } = await ApiService.put(`/trial/${params.id}/survey`, {
      tempTitle: params.tempTitle,
      tempDescription: params.tempDescription,
    });
    return data;
  },
  async modifyTrialProgress({ commit }, params) {
    await ApiService.put(`/trial/${params.id}/progress`, {
      progress: params.progress,
    });
    commit('setTrialProgress', params.progress);
  },
  async getTrialSurvey(context, id) {
    const { data } = await ApiService.get(`/api/trial/${id}`);
    return data;
  },
  async participateForFp({ commit }, id) {
    const { data } = await ApiService.get(`/api/trial/${id}`);
    commit('setParticipantToken', data.participantToken);
    return data;
  },
  async participate({ commit }, params) {
    const urlParams = {
      params: {
        utm_source: params.utmSource,
        utm_medium: params.utmMedium,
        utm_campaign: params.utmCampaign,
        utm_content: params.utmContent,
      },
    };
    const { data } = await ApiService.getWithQueryParams(`/api/participate/${params.id}`, urlParams);
    commit('setParticipantToken', data.participantToken);
    return data;
  },
  async fetchTrialQuestions({ commit }, id) {
    const { data } = await ApiService.get(`/api/trial/questionnaire/${id}`);
    commit('setTrialQuestions', data);
  },
  async deleteUploadedFileName({ commit }, id) {
    await ApiService.put(`/trial/${id}/deleteExcelName`);
    commit('setTrialSurveyUploadedFileName', '');
  },
  async submitAnswers(context, param) {
    const { data } = await ApiService.post(`/api/trial/submission/${param.id}`, {
      id: param.id,
      trialAnswerList: param.param,
      participantToken: param.participantToken,
    });
    return data;
  },
  // async submitAnswersForFp(context, param) {
  //   const { data } = await ApiService.post(`/api/trial/fp-submission/${param.id}`, {
  //     id: param.id,
  //     trialAnswerList: param.param,
  //   });
  //   return data;
  // },
  async getMyLastSubmissionResult({ commit }, id) {
    const { data } = await ApiService.get(`/api/trial/result/${id}/last`);
    commit('setMySubmissionResult', data);
  },
  async getFpSubmissionResult({ commit }, id) {
    const { data } = await ApiService.get(`/api/trial/fp-result/${id}`);
    commit('setMySubmissionResult', data);
  },
  async resetResult(context, id) {
    const { data } = await ApiService.delete(`/api/trial/result/${id}`);
    return data;
  },
  async getStats() {
    const { data } = await ApiService.get('/stats');
    return data;
  },
  async getUsage() {
    const { data } = await ApiService.get('/stats/usage');
    return data;
  },
  async savePersonalInfo(context, param) {
    const { data } = await ApiService.post('/api/trial/personal', param);
    return data;
  },
  async saveConsultingMessage(context, params) {
    const data = await ApiService.post('/api/trial/personal/consulting', params);
    return data;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
