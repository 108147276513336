import { ApiService } from '@/common/api.service';

const addTrialQuestionResultNo = (categories) => {
    let befQuestionCount = 0;
    return !categories ? [] : categories.map((category, index) => {
      if (index !== 0) {
        befQuestionCount += categories[index - 1].questionResults.length;
      }
      const questionResults = !category.questionResults ? [] : category.questionResults
        .map((question, qIndex) => ({
          ...question,
          no: `${befQuestionCount + qIndex + 1}`,
        }));
      return {
        ...category,
        questionResults,
        categoryIndex: index,
      };
    });
  };

const state = {
  trialFeedback: {
    categoryResults: [],
    average: 0,
    todayCount: 0,
    weekCount: 0,
    monthCount: 0,
    totalCount: 0,
  },
};

const getters = {
  trialFeedback(_state) {
    return _state.trialFeedback;
  },
};

const mutations = {
  setTrialResult(_state, data) {
    _state.trialFeedback.categoryResults = addTrialQuestionResultNo(data.trialCategoryResults);
    _state.trialFeedback.todayCount = data.todayCount;
    _state.trialFeedback.weekCount = data.weekCount;
    _state.trialFeedback.monthCount = data.monthCount;
    _state.trialFeedback.totalCount = data.totalSubmissionCount;
    _state.trialFeedback.average = data.average;
  },
};

const actions = {
  async fetchTrialFeedback({ commit }) {
    const { data } = await ApiService.post('/trial/feedback');
    commit('setTrialResult', data);
  },
  // TODO 여기로 가져오기
  async getTrialCategoryResultsList(context, { id, Items }) {
    const { data } = await ApiService.post(`/trial/feedback/${id}/category`, !Items ? [] : Items);
    return {
      categoryResults: addTrialQuestionResultNo(data.categoryResults),
    };
  },
  async getTrialSubmissionsForExcel() {
    const { data } = await ApiService.get('/trial/feedback/excel');
    return data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
