import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
} from 'vee-validate';
import Vue from 'vue';
import * as defaultVeeRules from 'vee-validate/dist/rules';
import i18n from '../../i18n';

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

extend('id', {
  validate: value => /^[a-z0-9.]*$/.test(value),
});
extend('name', {
  validate: value => /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9. ]*$/.test(value),
});
extend('mobile', {
  validate: value => value.length >= 9 && value.length <= 15,
});
extend('required', {
  ...defaultVeeRules.required,
  computesRequired: true,
  message(field, values) {
    if (field === 'ID') {
      return i18n.t('validation.requiredID');
    }
    if (field === 'PW') {
      return i18n.t('validation.requiredPW');
    }
    if (field === 'activity-title') {
      return i18n.t('validation.requiredActivityName');
    }
    if (field === 'activity-description') {
      return i18n.t('validation.requiredActivityDescription');
    }
    return i18n.t('validation.required', values);
  },
});
extend('required_if', {
  ...defaultVeeRules.required_if,
  computesRequired: true,
  message(field) {
    if (field === 'PW') {
      return i18n.t('validation.requiredPW');
    }
    return i18n.t('validation.required_if');
  },
});
extend('email', {
  ...defaultVeeRules.email,
});
extend('password', {
  validate: value => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/
    .test(value),
  // validate: value => /^[a-zA-Z0-9~!@#$%^*()_+-]*$/.test(value),
});
extend('replaceNaValue', {
  validate: value => value >= 1 && value <= 5,
});
extend('password_check', {
  computesRequired: true,
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
});
extend('min', {
  ...defaultVeeRules.min,
});
extend('max', {
  ...defaultVeeRules.max,
});
extend('checkUser', {
  params: ['isValidUsername'],
  validate(value, { isValidUsername }) {
    return isValidUsername === 'true';
  },
});
extend('checkEmail', {
  params: ['isValidEmail'],
  validate(value, { isValidEmail }) {
    return isValidEmail === 'true';
  },
});
extend('checkMobile', {
  params: ['isValidMobile'],
  validate(mobile, { isValidMobile }) {
    return isValidMobile === 'true';
  },
});
extend('login', {
  params: ['isLoginSuccess', 'loginFailCount'],
  validate(name, { isLoginSuccess }) {
    return !!isLoginSuccess;
  },
  message(name, { loginFailCount }) {
    if (loginFailCount >= 5) {
      return i18n.t('validation.exceedPassword');
    }
    if (loginFailCount >= 3 && loginFailCount < 5) {
      return i18n.t('validation.wrongPassword', { loginFailCount });
    }
    return i18n.t('validation.login');
  },
});
extend('approved', {
  params: ['isApproved', 'isLoginSuccess'],
  validate(name, { isApproved, isLoginSuccess }) {
    return !isLoginSuccess || isApproved;
  },
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
