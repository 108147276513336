import { ApiService } from '@/common/api.service';

const actions = {
  async getPrivacyAndPolicy() {
    const { data } = await ApiService.get('/api/privacyAndPolicy');
    return data;
  },
};

export default {
  actions,
};
