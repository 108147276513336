// main mode
export const mainPage = {
  DEFAULT: 'DEFAULT',
  REPORT: 'COMPARE',
};
// survey view type
export const surveyViewType = {
  GRID: 'GRID',
  LIST: 'LIST',
};
export const groupTabType = {
  GROUP: 'GROUP',
  EDIT: 'EDIT',
};
// category type
export const categoryType = {
  BACKGROUND_SURVEY: 'BACKGROUND_SURVEY',
  CATEGORY: 'CATEGORY',
};
// progress
export const progressType = {
  CREATING: 'CREATING',
  TEMP_CREATING_DONE: 'TEMP_CREATING_DONE',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
};

// survey page
export const surveyPage = {
  INTRO_PAGE: 'INTRO_PAGE',
  SURVEY_PAGE: 'SURVEY_PAGE',
};

export const deliveryPage = {
  INTRO_PAGE: 'INTRO',
  EMAIL_EDITOR_PAGE: 'EMAIL_EDITOR',
  HISTORY_PAGE: 'HISTORY',
};

export const sortType = {
  NONE: 'NONE',
  ASC: 'asc',
  DESC: 'desc',
};

export const targetList = [
  '선택안함',
  'Acceptance Test',
  'Accessibility',
  'Achievability',
  'Agreement',
  'Analysis',
  'Automated test',
  'Automation',
  'Backup & Recovery',
  'Build frequency',
  'Change tracking',
  'CI(Continuous Integration)',
  'Clear Picture',
  'Collaboration',
  'Communication',
  'Comprehend',
  'Consistency',
  'Container(서비스형)',
  'Conversation',
  'Cross-functional',
  'Current Approach',
  'Description',
  'Disposition',
  'Escalation',
  'Estimation',
  'Estimation, Velocity',
  'Extended team',
  'Extra effort',
  'Feedback',
  'Feedback used',
  'Identification',
  'Improvement',
  'Inspection',
  'Inspection/Security',
  'Integrity',
  'Just-in-time detailed plan',
  'Level of planning',
  'Linkage',
  'Micro-increments',
  'Notification',
  'Optimization',
  'Optmized',
  'Optmized(서비스형)',
  'Overall Satisfaction',
  'Prediction',
  'Pride',
  'Prioritized backlog',
  'Problem Solution',
  'Process',
  'Process/System',
  'Recommendation',
  'Regression Test',
  'Retrospective',
  'Risk evaluation',
  'Risk Reduction',
  'Role',
  'Segments',
  'Self-directing',
  'Stakeholders',
  'Standard',
  'Strategy',
  'Success criteria',
  'Sustainable pace',
  'Test Case',
  'Time-boxed iterations',
  'Traceability',
  'Transparency',
  'Understandability',
  'Use',
  'Values and Culture',
  'Visible progress',
  'Visualization',
  'Whole team',
  'Working Increment',
];

export const tagList = [
  'Architecture',
  'CI/CD',
  'Collaboration',
  'Data',
  'Infrastructure',
  'Iterative Development',
  'Monitoring',
  'MSA',
  'Planning',
  'Process',
  'Requirement Management',
  'Satisfaction',
  'Team',
  'Test',
  'User Story',
  'Vision',
  'DMM_MFGGEN',
  'DRC',
  'DMM_CLOUD',
];

export const activityTypeList = [
  'Action item 도출',
  'Icebreaking',
  'Idea 발산',
  'Idea 수정',
  'Retrospect',
  'Team building',
  'Vision share',
  '기타',
];

export const buttonWrapper = (url, text) => (`
  <a style="display: inline-block; min-width: 274px; min-height: 42px; line-height: 42px; background-color:#2a324b; color: #ffffff; font-size: 16px; vertical-align: middle; text-decoration: none;"
    href="${url}" target="_blank">
    ${text}
  </a>
`);

export const SENDER_NAME = 'SALLY SURVEY';
export const SENDER_EMAIL = 'admin@sally.coach';

export const errorType = {
  NOT_SUPPORT_IE: 'IE',
  NOT_SUPPORT_MOBILE: 'MOBILE',
};

export const authorityType = {
  ADMIN: 'ADMIN',
  REGISTER_USER: 'REGISTER_USER',
  ACTIVITY: 'ACTIVITY',
  QUESTION_BANK: 'QUESTION_BANK',
  TEMPLATE: 'TEMPLATE',
  MY_SURVEY: 'MY_SURVEY',
  ALL_SURVEY: 'ALL_SURVEY',
  CREATE_SURVEY: 'CREATE_SURVEY',
  DELIVERY_SURVEY: 'DELIVERY_SURVEY',
  SURVEY_FEEDBACK: 'SURVEY_FEEDBACK',
  SUBMISSION: 'SUBMISSION',
  SURVEY_RESULT: 'SURVEY_RESULT',
  SURVEY_REPORT: 'SURVEY_REPORT',
  LAB: 'LAB',
  MANAGE_TRIAL: 'MANAGE_TRIAL',
};

export const deliveryType = {
  EMAIL: 'EMAIL',
  INDIVIDUAL_EMAIL: 'INDIVIDUAL_EMAIL',
  URL: 'URL',
};
